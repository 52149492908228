import { Checkbox, FormControl, FormControlLabel, RadioGroup, RadioGroupProps } from "@material-ui/core";
import { FC } from "react";

interface CheckboxType extends RadioGroupProps {
    register: any,
    label: string,
    id: string,
    values: string[],
};

export const CheckboxListHooked: FC<CheckboxType> = ({ register, id, label, values, ...inputProps }) => {
    return <FormControl style={{ width: "100%" }}>
        <RadioGroup>
            {
                values.map((value) => {
                    return (<FormControlLabel value={value}
                        control={<Checkbox id={id} {...register(id)} />}
                        label={value}
                        name={value} />)
                })
            }
        </RadioGroup>
    </FormControl>
}