import DiamondIcon from '@mui/icons-material/Diamond';
import HomeIcon from '@mui/icons-material/Home';
import TourIcon from '@mui/icons-material/Tour';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Box from '@mui/material/Box';
import React, { Suspense, useState } from "react";
import { AgentTable } from '../components/Agents/AgentsTable';
import { ProjectDrawer } from '../components/ProjectDrawer';
import { PropertyTable } from "../components/Properties/Advertisement/PropertiesTable"
import { AgentForm } from '../components/Agents/AddAgents';
import { FullWidthDialog } from '../components/Dialog/FullWidthDialog';
import Button from '@mui/material/Button/Button';
import AddHomeIcon from '@mui/icons-material/AddHome';
import { CreatePropertyForm } from '../components/Properties/Form/CreatePropertyForm';
const Visitors = React.lazy(() => import('../components/Visitors'));

interface ProjectProps {
    projectSelector: () => void;
}

const listItems = [
    {
        name: "Agent",
        icon: <DiamondIcon />
    },
    {
        name: "Property",
        icon: <HomeIcon />,
    },
    {
        name: "Add property",
        icon: < AddHomeIcon />,
    },
    {
        name: "Visit",
        icon: <TourIcon />
    },
]
export const Project = (props: ProjectProps) => {
    const [selectedTab, setTab] = useState(listItems[0].name);
    const [openAgentForm, setOpenAgentForm] = useState(false);

    const container = React.useMemo(() => {
        switch (selectedTab) {
            case "Agent":
                return <><Button onClick={() => { setOpenAgentForm(true) }}>Add Agent</Button><AgentTable /></>
            case "Property":
                return <PropertyTable />
            case "Add property":
                return <CreatePropertyForm />
            default:
                return selectedTab;
        }
    }, [selectedTab]);

    return (
        <Box sx={{ display: 'flex' }}>
            <ProjectDrawer listItemOptions={listItems} onBackClick={props.projectSelector} onOptionClick={setTab} />
            <Box style={{width:"100%"}}>
                <Suspense fallback={<div>Loading...</div>}>
                    {container}
                </Suspense>
            </Box>
            <FullWidthDialog open={openAgentForm} onClose={() => {
                setOpenAgentForm(false);
            }} title={'Agent'} >
                <AgentForm />
            </FullWidthDialog>
        </Box>
    );
}