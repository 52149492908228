import { useIsAuthenticated } from "@azure/msal-react";
import React from 'react';
import ProfileContent from "./components/ProfileContent";
import { SignInButton } from "./components/SignInButton";
import { EnvInitializer } from "./initializers/EnvInitializer";
import { ProjectSelector } from "./pages/ProjectSelector";
import "./styles/App.css";

export function App() {
    const isAuthenticated = useIsAuthenticated();
    return (
        <EnvInitializer>
            {isAuthenticated ?
                <>
                    <ProfileContent />
                    <ProjectSelector />
                </>
                :
                <SignInButton />}
        </EnvInitializer>
    );
}