import { Typography } from "@mui/material";
import { AddressLookup, LookUp } from "./PostCodeLookup";
import { PropertyStatus } from "./PropertyStatus";
import { PropertyType } from "./PropertyType";

export interface IEssentialPart {
    children: React.ReactNode;
    gap: string;
    maxWidth: string;
    register: any;
}
export const EssentialPart = (props: IEssentialPart) => {
    const typographyStyles = { color: "#515A5A", margin: "20px 0 8px 0" };
    return (
        <>
            <Typography style={{ color: "#515A5A", margin: "20px 0 8px 0" }}>Select the type of property</Typography>
            <PropertyType register={props.register} />

            <div style={{ maxWidth: "400px" }}>
                <Typography style={{ color: "#515A5A", margin: "20px 0 8px 0" }}>Enter post code and hit find</Typography>
                <AddressLookup register={props.register} />
            </div>

            {props.children}
            <Typography style={typographyStyles}>Property status</Typography>
            <PropertyStatus register={props.register} />
        </>)
}