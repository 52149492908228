import { FormControl, FormControlLabel, Radio, RadioGroup, RadioGroupProps } from "@material-ui/core";
import { FC } from "react";

interface RadioType extends RadioGroupProps {
    register: any,
    label: string,
    id: string,
    values: string[]
};

export const RadioHooked: FC<RadioType> = ({ register, id, label, values, ...inputProps }) => {
    return <FormControl>
        <RadioGroup
            {...register(id)}
            {...inputProps}
            id={id}
            label={label}>
            {
                values.map((value) => {
                    return (<FormControlLabel 
                        {...register(id)} value={value} control={<Radio />} label={value} />)
                })
            }
        </RadioGroup>
    </FormControl>
}