import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export interface IDialog {
    open: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode
}

export const FullWidthDialog = (props: IDialog) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
        </Dialog>
    );
}