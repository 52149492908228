import bedSVG from '../../../images/icons/bed.svg';
import bathroomSVG from '../../../images/icons/bathroom.svg';
import livingroomSVG from '../../../images/icons/livingroom.svg';
import React from "react";
import Chip from "@mui/material/Chip";

export interface IPropertyFeatures {
  bedroom: number;
  bathroom: number;
  livingroom: number;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export const PropertyFeatures = (props: IPropertyFeatures) => {
  return (
    <div style={{ display: "grid", gap: "4px", gridTemplateColumns: `max-content max-content max-content` }}>
      <span>
        <Chip icon={<img src={bathroomSVG} alt="" />} label={`${props.bathroom}xBathroom`} />
      </span>
      <span>
        <Chip icon={<img src={bedSVG} alt="" />} label={`${props.bedroom}xBedroom`} />
      </span>
      <span>
        <Chip icon={<img src={livingroomSVG} alt="" />} label={`${props.livingroom}xLounge`} />
      </span>
    </div>
  );
};
