import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../graph";
import { loginRequest } from "../authConfig";
import { Header } from "./Header"

export default function ProfileContent() {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState<any>(null);

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
    }, [accounts, instance]);

    return (
        <>
            {graphData && (<Header userName={graphData.displayName} />)}
        </>
    );
};
