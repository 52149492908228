
import { RadioHooked } from "../../FormComponents/RadioHooked";

const propertyStatus = ["chain free", "price reduced", "under offer", "sold STC"];

export const PropertyStatus = (props: { register: any }) => {
    return (
        <>
            <RadioHooked register={props.register} label={"Property Status"}
                id={"propertyStatus"} values={propertyStatus} />
        </>
    )
}