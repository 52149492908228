import React from "react";
import { updateEnvUrl } from "../envHandler/envHandler";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const EnvInitializer = (props: React.PropsWithChildren<unknown>) => {
  React.useEffect(() => {
    updateEnvUrl();
  }, []);

  return <>{props.children}</>;
};
