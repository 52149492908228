import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarIcon from '@mui/icons-material/Star';
import { PropertyFeatures } from "./PropertyFeatures";

export interface IProperty {
  available: number;
  createdDate: string;
  paid: number;
  propertyType: string;
  propertyId: string;
  address: string;
  bedRooms: number;
  bathRooms: number;
  livingRooms: number;
  listingPrice: number,
  featured: number,
  cover: string,
  agent: string,
  onPropertyClick: (propertyId: string) => void;
}

export const PropertyCard = (props: IProperty) => {
  return (
    <div style={{
      marginTop: "10px", position: "relative",
      maxWidth: "100%"
    }}
      onClick={() => {
        if (props.paid === 0) {
          return;
        }
        props.onPropertyClick(props.propertyId)
      }
      }>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="cover"
          style={{ height: "200px", width: "300px", borderRadius: '12px' }}
          image={props.cover}
        />
        <div style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          textAlign: "left"
        }}>
          <Typography style={{
            backgroundColor: "#B67929",
            borderRadius: "7px", color: "white", padding: "8px 10px",
          }}>
            {Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(props.listingPrice)}
          </Typography>
        </div>
        {(props.paid === 0) && <div style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          textAlign: "left"
        }}>
          <Typography style={{
            backgroundColor: "#B67929",
            borderRadius: "7px", color: "white", padding: "8px 10px",
          }}>
            Unpaid
          </Typography>
        </div>}
        {(props.paid === 1 && props.available === 0) && (<div style={{
          position: "absolute",
          top: "55px",
          right: "10px",
          textAlign: "left"
        }}>
          <Typography style={{
            backgroundColor: "#B67929",
            borderRadius: "7px", color: "white", padding: "8px 10px",
          }}>
            Review
          </Typography>
        </div>)}
        {(props.featured === 1) && (<div style={{
          position: "absolute",
          top: "55px",
          right: "10px",
          textAlign: "left"
        }}>
          <Typography style={{
            backgroundColor: "#00243F",
            borderRadius: "7px", color: "white", padding: "8px 10px",
          }}>
            <StarIcon style={{ color: "#B67929", fontSize: "20px", marginTop: "-3px" }} /> Featured
          </Typography>
        </div>)}
        <CardContent>
          <div className='flex justify-center'>
            <Typography variant="h6" component="div" style={{ color: "#00243F", fontWeight: "bold" }}>
              <LocationOnIcon style={{ marginLeft: "-20px", marginTop: "-8px" }} />{props.address}
            </Typography>
            <Typography style={{ color: "#00243F"}}>
              agent: {props.agent}
            </Typography>
          </div>
        </CardContent>
        <CardActions style={{ marginTop: "-15px" }}>
          <PropertyFeatures
            bedroom={props.bedRooms}
            bathroom={props.bathRooms}
            livingroom={props.livingRooms} />
        </CardActions>
      </CardActionArea>
    </div>
  );
};
