import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 400,
      height: 400,
      marginLeft: 'auto',
    },
    loginBtn: {
      marginTop: theme.spacing(3),
      flexGrow: 1,
      height: "40px",
      width: "10px",
      border: '5px'
    },
    header: {
      marginTop: theme.spacing(14),
      textAlign: 'center',
      background: '#212121',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      fontStyle: 'Times new Roman'
    },
    card: {
      marginTop: theme.spacing(10),
      marginRight: theme.spacing(5),
      background: '#212121',
      color: '#fff',
      height: 450,
      width: 500,
      borderRadius: 25
    },

  })
);

export const SignInButton = () => {
  const classes = useStyles();

  const { instance } = useMsal();

  const handleLogin = (loginType: String) => {
    instance.loginPopup(loginRequest)
  }
  return (

    <div style={{
      backgroundImage: "url(/Background.png)",
      height: "650px"
    }}>
      <form className={classes.container}>
        <Card className={classes.card}>
          <CardHeader className={classes.header} title="MAPLIN WORLD" />
          <CardContent>
            <div>
              <CardActions>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.loginBtn}
                  onClick={() => handleLogin('popup')}
                >
                  Login
                </Button>
              </CardActions>
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );
}