import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

const drawerWidth = 240;
type ListItemOption = {
    name: string;
    icon: JSX.Element;
}
interface IProjectDrawer {
    listItemOptions: ListItemOption[];
    onBackClick: () => void;
    onOptionClick: (option: string) => void;
}

export const ProjectDrawer = (props: IProjectDrawer) => {
    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    {
                        props.listItemOptions.map(item => {
                            return (<ListItem key={item.name} disablePadding>
                                <ListItemButton onClick={() => props.onOptionClick(item.name)}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>)
                        })
                    }
                    <ListItem key={"Back"} disablePadding>
                        <ListItemButton onClick={props.onBackClick}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Back"} />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
            </Box>
        </Drawer>
    );
}