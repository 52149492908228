import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { formats, modules } from "./EditorToolbar";
import "./styles.css";
import { useFormikContext } from "formik";
// eslint-disable-next-line @typescript-eslint/naming-convention
export const DescriptionBox = (props: { name: string }) => {
  const { setFieldValue, values } = useFormikContext<typeof props.name>();
  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        value={values[props.name as any]}
        onChange={(value) => {
          setFieldValue(props.name, value);
        }}
        placeholder={"Property description..."}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};
