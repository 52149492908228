import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { useCallback, useState } from 'react';
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { EssentialPart } from './EssentialPart';
import { Image } from "./ImageItem";
import { PropertyDetailsPart } from './PropertyDetailsPart';
import axios from "axios";

export enum Plan {
    "core",
    "professional",
    "featured"
}

export interface IPropertyDetails {
    propertyType: string;
    propertyStatus: string;
    lookUp: string;
    listingPrice: string;
    bedrooms: string;
    livingrooms: string;
    bathrooms: string;
    propertyFeatures: string[];
    tenure: string;
}

export const CreatePropertyForm = () => {
    const { register, handleSubmit } = useForm<IPropertyDetails>();
    const productType = Plan[2];
    const addType = "sell";
    const [description, setDescription] = useState("");
    const [added, setAdded] = useState(false);
    const [error, setError] = useState(false);
    const [images, setImages] = useState<{
        [id: string]: Image[];
    }>({
        "front": [],
        "rear": [],
        "living": [],
        "bedroom": [],
        "bathroom": [],
        "kitchen": [],
        "other": [],
        "floorplan": [],
        "epc": [],
    });
    const gap = "25px";
    const maxWidth = "250px";
    const onSubmit = useCallback(async (data: IPropertyDetails) => {
        const { address, latitude, longitude, postCode } = JSON.parse(data.lookUp);
        const propertyDetails = {
            address: `${address} ${postCode}`,
            latitude,
            longitude,
            "bedRooms": data.bedrooms,
            "bathRooms": data.bathrooms,
            "livingRooms": data.livingrooms,
            "description": description,
            "features": data.propertyFeatures,
            "propertyType": data.propertyType,
            "listingPrice": data.listingPrice,
            "offer": productType,
            "viewingTimeSlot": {},
            "tenure": data.tenure,
            "propertyStatus": data.propertyStatus
        }
        console.log(propertyDetails);
        const response = await axios.post(`${globalThis.ENV_BASED_URL}sell-a-property-admin`, propertyDetails);
        const propertyId = response.data.propertyId;
        if (images["front"][0]) {
            const coverImageData = new FormData();
            const coverImage = images["front"][0].file!
            coverImageData.append('file', coverImage, images["front"][0].fileName);
            const url = `${globalThis.ENV_BASED_URL}save-property-cover-image/${propertyId}`;
            await axios.post(url, coverImageData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
        }
        Object.keys(images).forEach(async (key) => {
            const imageList = images[key];
            if (imageList.length === 0)
                return;
            imageList.forEach(async (image) => {
                const imageFile = new FormData();
                imageFile.append("file", image.file);
                const url = `${globalThis.ENV_BASED_URL}save-property-image/${propertyId}/${key}`;
                await axios.post(url, imageFile, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            })
        })
        setAdded(true);
    }, [description, productType, images]);

    return (
        <div style={{ padding: "2%" }}>
            <Typography style={{ color: "#515A5A", margin: "20px 0 8px 0" }}>Product type {productType}</Typography>
            <Typography style={{ color: "#515A5A", margin: "20px 0 8px 0" }}>Add type {addType}</Typography>
            <EssentialPart gap={gap} maxWidth={maxWidth} register={register}>
                <PropertyDetailsPart gap={gap} maxWidth={maxWidth} register={register}
                    description={description} setDescription={setDescription}
                    images={images} setImages={setImages} />
            </EssentialPart>

            <div style={{ marginTop: "15px" }}>
                <Button
                    style={{ backgroundColor: "#B67929", color: "white", fontWeight: "bold", fontSize: "16px" }}
                    onClick={handleSubmit(onSubmit)}>Save</Button>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={added}
                onClose={() => { setAdded(false) }}
                message="Property created"
                key={"vertical horizontal"}
            />
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={error}
                onClose={() => { setError(false) }}
                message="Required fields are empty"
                key={"vertical horizontal"}
            />
        </div>
    );
}