import { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Chip
} from '@mui/material';

export interface Agent {
    agencyId: string,
    name: string,
    email: string,
    workingLocation: string,
    commercial: number,
    residential: number,
}

export const AgentTable = () => {

    const [agents, setAgents] = useState<Agent[]>([]);

    useEffect(() => {
        void axios.get(`${globalThis.ENV_BASED_URL}get-all-agents`)
            .then((resp) => {
                const data: Agent[] = resp.data;
                console.log(data);
                setAgents(data)
            })
    }, []);

    return (
        <Table style={{ width: "100%" }}>
            <TableHead>
                <TableRow>
                    <TableCell><b>Name</b></TableCell>
                    <TableCell><b>Email</b></TableCell>
                    <TableCell><b>Working Location</b></TableCell>
                    <TableCell><b>Working Property </b></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {agents.map((row: any) => (
                    <TableRow id={row.agencyId}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.workingLocation}</TableCell>
                        <TableCell>
                            <div style={{ display: "grid", gap: "4px", gridTemplateColumns: `max-content max-content max-content` }}>
                                {(row.residential === 1) && <span>
                                    <Chip label="residential" />
                                </span>}
                                {(row.commercial === 1) && <span>
                                    <Chip label="commercial" />
                                </span>}

                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}