
import ReactQuill from "react-quill";
import EditorToolbar, { formats, modules } from './EditorToolbar';
import "./styles.css";
export const DescriptionBox = (props: { description: string, setDescription: (desscription: string) => void }) => {
    return (
        <div className="text-editor">
            <EditorToolbar />
            <ReactQuill
                value={props.description}
                onChange={props.setDescription}
                placeholder={"Property description..."}
                modules={modules}
                formats={formats}
            />
        </div>
    )
}