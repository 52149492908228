import React, { useState, useCallback } from "react";
import { ProjectGrid } from "../components/ProjectGrid";
import { Project } from "./Project";

export const ProjectSelector = () => {
    const [showProject, setShowProject] = useState(false);
    const openProject = useCallback(() => { setShowProject(true) }, []);
    const openProjectSelector = useCallback(() => { setShowProject(false) }, [])
    return (
        <>
            {!showProject ?
                <ProjectGrid showProjectCallback={openProject} />
                :
                <Project projectSelector={openProjectSelector} />}
        </>
    )
}