import { OutlinedTextFieldProps, TextField } from "@material-ui/core";
import { FC } from "react";

interface TextFieldType extends OutlinedTextFieldProps {
    register: any,
    label: string,
    id: string,
};

export const TextFieldHooked: FC<TextFieldType> = ({ register, id, label, ...inputProps }) => {
    return <TextField
        {...register(id)}
        id={id}
        label={label}
        fullWidth
        {...inputProps}
    />
}