import { ProjectCard } from "./ProjectCard";
import maplinHomesLogo from "../logos/maplinhomes.png";
// import maplinStudioLogo from "../logos/maplinstudio.png";
// import maplinHubLogo from "../logos/maplinhub.png";
// import maplinEnergyLogo from "../logos/maplinenergy.png";
// import maplinEngineeringLogo from "../logos/maplinengineering.png";
const projects = [
    {
        "name": "maplin-homes",
        "logo": maplinHomesLogo
    },
    // {
    //     "name": "maplin-studio",
    //     "logo": maplinStudioLogo
    // },
    // {
    //     "name": "maplin-hub",
    //     "logo": maplinHubLogo
    // },
    // {
    //     "name": "maplin-energy",
    //     "logo": maplinEnergyLogo
    // },
    // {
    //     "name": "maplin-energy",
    //     "logo": maplinEngineeringLogo
    // }
]

interface ProjectGridProps {
    showProjectCallback: () => void;
}
export const ProjectGrid = (props:ProjectGridProps) => {
    return (
        <div style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "10px"
        }}>
            {
                projects.map((project) => {
                    return <ProjectCard alternate={project.name} image={project.logo} onClick={props.showProjectCallback}/>
                })
            }
        </div >
    );
}
