import React from "react";
interface GridLaoutProps {
    children: React.ReactNode;
    gap:string;
    maxWidth:string;
}
export const GridLayout = ({ children, gap, maxWidth }: GridLaoutProps) => {
    return (
        <div style={{
            maxWidth: "100%", display: "grid",
            gap: gap, gridTemplateColumns: `repeat(auto-fit, minmax(${maxWidth}, 1fr))`
        }}>
            {children}
        </div>
    )
}