import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { DropdownHooked } from '../../FormComponents/DropdownHooked';
export interface AddressLookupProps {
    register: any
}
export interface LookUp {
    postCode: string,
    address: string,
    latitude: number,
    longitude: number
}
export const AddressLookup = ({ register }: AddressLookupProps) => {
    const [postalCode, setPostalCode] = useState("");
    const [addresses, setAddresses] = useState<LookUp[] | undefined>(undefined);
    const [userAddress, setUserAddress] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);
    const fetchAddresses = useCallback(async () => {
        try {
            if (postalCode === "") {
                setError("invalid post code");
                return;
            }
            const response = await axios.get(`${globalThis.ENV_BASED_URL}addressLookup/${postalCode}`);
            setAddresses(response.data);
            setError(undefined);
        } catch (error) {
            console.log(error);
            setAddresses(undefined);
            setUserAddress("");
            setError("Error retrieving addresses");
        }
    }, [postalCode]);
    const component = useMemo(() => {
        if (!addresses)
            return <></>
        if (error)
            return error;
        const values = addresses.map(({ address, postCode, longitude, latitude }) => {
            return { value: JSON.stringify({ address, postCode, longitude, latitude }), name: address };
        })
        return (
            <div style={{ marginTop: "8px" }}>
                <DropdownHooked register={register} label={"Pick your address"}
                    id={"lookUp"} values={values} />
            </div>
        )
    }, [addresses, error, register]);
    return (
        <>
            <FormControl fullWidth>
                <TextField size="small" id="postalCode" label="Postal code" variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <Button
                                style={{ backgroundColor: "#00243F", color: "white", fontWeight: "bold", fontSize: "16px", marginRight: "-15px" }}
                                onClick={() => {
                                    setAddresses(undefined);
                                    fetchAddresses();
                                }} startIcon={<SearchIcon />}>Find</Button>
                        </InputAdornment>
                    }}
                    fullWidth={true}
                    onChange={(e) => setPostalCode(e.target.value)} />

                {component}
            </FormControl>
        </>
    )
}