import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
interface ProjectCardProps {
    image: string;
    alternate: string;
    onClick: () => void;
};
export const ProjectCard = ({ image, alternate, onClick }: ProjectCardProps) => {
    return (
        <Card sx={{ minWidth: 330 }} style={{ margin: "8px" }}>
            <CardActionArea onClick={onClick}>
                <CardMedia
                    component="img"
                    height="150"
                    image={image}
                    alt={alternate} />
            </CardActionArea>
        </Card>
    );
}
