
import { InputAdornment, TextField, Typography } from "@mui/material";
import { DescriptionBox } from "./DescriptionBox/DescrptionBox";
import { GridLayout } from "./GridLayout";
import { SelectCustom } from "./SelectCustom";
import { PropertyFeature } from "./PropertyFeature";
import { UploadPropertyImages } from "./UploadPropertyImages";
import { Image } from "./ImageItem";
import { useCallback, useState } from 'react';
import { PropertyTenure } from "./PropertyTenure";
import { TextFieldHooked } from "../../FormComponents/TextFieldHooked";
import { DropdownHooked } from "../../FormComponents/DropdownHooked";

export interface IImageSet {
    [id: string]: Image[];
}
export interface IPropertyDetailsPart {
    gap: string;
    maxWidth: string;
    register: any;
    description: string;
    setDescription: (description: string) => void;
    images: IImageSet;
    setImages: (images: IImageSet) => void;
}
export const PropertyDetailsPart = (props: IPropertyDetailsPart) => {
    const typographyStyles = { color: "#515A5A", margin: "20px 0 8px 0" };
    
    const onUpload = useCallback((key: string, imagesToAdd: Image[]) => {
        props.setImages({ ...props.images, [key]: imagesToAdd });
    }, [props])
    return (
        <>
            <Typography style={typographyStyles}>Property front images (first image will also be used as cover image)</Typography>
            <UploadPropertyImages id={"front"} images={props.images.front} onUpload={onUpload} />
            <Typography style={typographyStyles}>Property rear images</Typography>
            <UploadPropertyImages id={"rear"} images={props.images.rear} onUpload={onUpload} />
            <Typography style={typographyStyles}>Living room images</Typography>
            <UploadPropertyImages id={"living"} images={props.images.living} onUpload={onUpload} />
            <Typography style={typographyStyles}>Bedroom images</Typography>
            <UploadPropertyImages id={"bedroom"} images={props.images.bedroom} onUpload={onUpload} />
            <Typography style={typographyStyles}>Bathroom images</Typography>
            <UploadPropertyImages id={"bathroom"} images={props.images.bathroom} onUpload={onUpload} />
            <Typography style={typographyStyles}>Kitchen images</Typography>
            <UploadPropertyImages id={"kitchen"} images={props.images.kitchen} onUpload={onUpload} />
            <Typography style={typographyStyles}>Images of some property feature like pool, garden, driveway....</Typography>
            <UploadPropertyImages id={"other"} images={props.images.other} onUpload={onUpload} />
            <Typography style={typographyStyles}>Floor plans</Typography>
            <UploadPropertyImages id={"floorplan"} images={props.images.floorplan} onUpload={onUpload} />
            <Typography style={typographyStyles}>EPC</Typography>
            <UploadPropertyImages id={"epc"} images={props.images.epc} onUpload={onUpload} />

            <div>
                <Typography style={typographyStyles}>Property tenure</Typography>
                <PropertyTenure register={props.register} />
            </div>

            <GridLayout gap={props.gap} maxWidth={props.maxWidth}>
                <div>
                    <Typography style={typographyStyles}>Listing price that you want to go with</Typography>
                    <TextFieldHooked register={props.register} label={"Listing price"} id={"listingPrice"}
                        variant={"outlined"} size="small" InputProps={{
                            startAdornment: <InputAdornment position="start">
                                &#163;
                            </InputAdornment>
                        }} />
                </div>
                <div>
                    <Typography style={typographyStyles}>Bedrooms in property</Typography>
                    <DropdownHooked register={props.register} label={"Bedrooms"}
                        id={"bedrooms"} values={[
                            { name: "1", value: "1" },
                            { name: "2", value: "2" },
                            { name: "3", value: "3" },
                            { name: "4", value: "4" },
                            { name: "5", value: "5" },
                            { name: "6", value: "6" },
                            { name: "7", value: "7" },
                            { name: "8", value: "8" },
                            { name: "9", value: "9" },
                            { name: "10", value: "10" }
                        ]} />
                </div>
            </GridLayout>

            <GridLayout gap={props.gap} maxWidth={props.maxWidth}>
                <div>
                    <Typography style={typographyStyles}>Living rooms in property</Typography>
                    <DropdownHooked register={props.register} label={"Livingroom"}
                        id={"livingrooms"} values={[
                            { name: "1", value: "1" },
                            { name: "2", value: "2" },
                            { name: "3", value: "3" },
                            { name: "4", value: "4" },
                            { name: "5", value: "5" },
                            { name: "6", value: "6" },
                            { name: "7", value: "7" }
                        ]} />
                </div>
                <div>
                    <Typography style={typographyStyles}>Bathrooms in property</Typography>
                    <DropdownHooked register={props.register} label={"Bathrooms"}
                        id={"bathrooms"} values={[
                            { name: "1", value: "1" },
                            { name: "2", value: "2" },
                            { name: "3", value: "3" },
                            { name: "4", value: "4" },
                            { name: "5", value: "5" }
                        ]} />
                </div>
            </GridLayout>
            <Typography style={typographyStyles}>Property features</Typography>
            <PropertyFeature register={props.register} />
            <Typography style={typographyStyles}>Description</Typography>
            <DescriptionBox description={props.description} setDescription={props.setDescription} />
        </>
    )
}