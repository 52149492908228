
import { FormControl } from "@mui/material";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from "react";
import { CheckboxListHooked } from "../../FormComponents/CheckboxListHooked";

const propertyFeatures = ["driveway", "front garden", "rear garden", "storage shed", "pool",
    "sun room", "conservatory", "loft storage", "insulated loft", "gated entrance", "burglary alarm"];

export const PropertyFeature = (props: {
    register: any
}) => {
    const [customPropertyFeatures, setCustomPropertyFeatures] = useState<string[]>([]);
    const [customFeature, setCustomFeature] = useState<string>("");
    return (
        <>
            <FormControl style={{ width: "100%" }}>
                <CheckboxListHooked register={props.register} label={"Property features"}
                    id={"propertyFeatures"} values={[...propertyFeatures, ...customPropertyFeatures]} />
                <div style={{ maxWidth: "400px" }}>
                    <TextField variant="outlined" value={customFeature} label={"Custom Feature"}
                        size={"small"} onChange={(e) => { setCustomFeature(e.target.value) }} />
                    <Button variant="contained" onClick={() => {
                        customPropertyFeatures.length === 0 ?
                            setCustomPropertyFeatures([customFeature]) :
                            setCustomPropertyFeatures([...customPropertyFeatures, customFeature]);
                        setCustomFeature("");
                    }}>Add Feature</Button>
                </div>
            </FormControl>
        </>
    )
}