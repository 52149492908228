
import { FormControl, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { useFormikContext } from "formik";
import { Typography } from "@material-ui/core";

export interface CheckboxListProps {
  name: string;
  label: string;
  listItem: string[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CheckboxList = (props: CheckboxListProps) => {
  const { setFieldValue, values } = useFormikContext<typeof props.name>();

  const onChange = React.useCallback((e, checked) => {
    const selectedValue = e.target.name;
    const selectedValues: any = values[props.name as any];
    if (checked) {
      selectedValues.push(selectedValue);
      setFieldValue(props.name, selectedValues);
      return;
    }
    const valueAt = values[props.name as any].indexOf(selectedValue);
    selectedValues.splice(valueAt, 1);
    setFieldValue(props.name, selectedValues);
  }, [props.name, setFieldValue, values]);

  return (
    <FormControl fullWidth>
      <Typography>{props.label}</Typography>
      {
        props.listItem.map(item => {
          return (
            <FormControlLabel
              control={
                <Checkbox name={item} onChange={onChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={(values[props.name as any]).includes(item)} />
              }
              label={item}
            />
          )
        })
      }

    </FormControl>
  );
};
