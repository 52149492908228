import Select from "@mui/material/Select";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import React from "react";
import { useFormikContext } from "formik";

export interface IMenuItem {
  label: string;
  value: string | number;
}

export interface DropDownProps {
  name: string;
  label: string;
  menuItems: IMenuItem[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DropDown = (props: DropDownProps) => {
  const { setFieldValue, values } = useFormikContext<typeof props.name>();

  const onChange = React.useCallback((event: any) => {
    const { value } = event.target;
    setFieldValue(props.name, value);
  }, [props.name, setFieldValue]);

  return (
    <FormControl fullWidth>
      <InputLabel id={props.name}>{props.label}</InputLabel>
      <Select
        id={props.name}
        labelId={props.name}
        size="small"
        label={props.label}
        value={values[props.name as any]}
        onChange={onChange}>
        {props.menuItems.map((menuItem) => {
          return (<MenuItem key={menuItem.value} value={menuItem.value}>{menuItem.label}</MenuItem>);
        })}
      </Select>
    </FormControl>
  );
};
