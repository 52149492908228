
import { RadioHooked } from "../../FormComponents/RadioHooked";

export const propertyTypesName = ["detached", "terraced","semi-detached", "flat", "bunglow", "land", "park home"];

export const propertyTypes = [
    { name: "detached" },
    { name: "semi-detached" },
    { name: "tarraced" },
    { name: "flat" },
    { name: "bunglow" },
    { name: "farm" },
    { name: "park home" },
];

export const PropertyType = (props: { register: any; }) => {
    return (
        <RadioHooked register={props.register} label={"Property Type"}
            id={"propertyType"} values={propertyTypesName} />
    )
}