import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormControlLabel, Checkbox, MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { Button, LinearProgress } from '@mui/material';
import { cities } from "./Cities";
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel, Select } from 'formik-mui';
import axios from "axios";
const registerSchema = object({
    name: string()
        .nonempty('Name is required')
        .max(32, 'Name must be less than 100 characters'),
    email: string().nonempty('Email is required').email('Email is invalid'),
    workingLocation: string()
        .nonempty('Working location is required')
        .max(32, 'Working location must be less than 32 characters'),
    propertyType: string()
        .nonempty('Working location is required')
        .max(32, 'Working location must be less than 32 characters'),
    bio: string()
        .nonempty('Please enter your Bio data')
        .max(200, 'Bio must be less than 200 characters'),
});

type RegisterInput = TypeOf<typeof registerSchema>;


interface Values {
    email: string;
    name: string;
    contactNumber: string;
    commercial: boolean;
    residential: boolean;
    bio: string;
    trustPilotUrl: string;
}

export const AgentForm = () => {
    const {
        register,
        formState: { errors, isSubmitSuccessful },
        reset,
        control,
        handleSubmit,
    } = useForm<RegisterInput>({
        resolver: zodResolver(registerSchema),
    });

    const onSubmitHandler: SubmitHandler<RegisterInput> = async (values) => {
        console.log(values);
        // try {
        //     await axios.post("https://o8a3lgcsy5.execute-api.us-east-1.amazonaws.com/add-agent",
        //         values);

        // } catch (error) {
        //     console.log(error);
        // }
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    return (
        <Box
            style={{ width: "100%" }}>
            <Formik
                initialValues={{
                    email: "",
                    contactNumber: "",
                    workingLocation: "",
                    bio: "",
                    name: "",
                    commercial: false,
                    residential: false,
                    trustPilotUrl: "https://uk.trustpilot.com/review/maplinhomes.com"

                }}
                validate={(values) => {
                    const errors: Partial<Values> = {};
                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    await axios.post(`${globalThis.ENV_BASED_URL}add-agent`,
                        values);
                    setSubmitting(false);
                }}
            >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <Field
                            component={TextField}
                            name="name"
                            label="Name"
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                        <br />
                        <Field
                            component={TextField}
                            type="email"
                            label="Email"
                            name="email"
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                        <br />
                        <Field
                            component={TextField}
                            label="Contact number"
                            name="contactNumber"
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                        <br />
                        <Field
                            component={Select}
                            id="workingLocation"
                            name="workingLocation"
                            labelId="working-location"
                            fullWidth={true}
                            size="small"
                            label="Working location">
                            {cities.map(value => {
                                return <MenuItem value={value}>{value}</MenuItem>
                            })}
                        </Field>
                        <br />
                        <Field
                            type="checkbox"
                            name="residential"
                            component={CheckboxWithLabel}
                            Label={{ label: "Residential" }}
                        />
                        <br />
                        <Field
                            type="checkbox"
                            name="commercial"
                            component={CheckboxWithLabel}
                            Label={{ label: "Commercial" }}
                        />
                        <br />
                        <Field
                            component={TextField}
                            type="text"
                            label="Bio"
                            name="bio"
                            size="small"
                            fullWidth
                        />

                        {isSubmitting && <LinearProgress />}
                        <br />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
