import { useCallback } from 'react';
import { GridLayout } from './GridLayout';
import { Image, UploadImage } from './ImageItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
export interface IUploadPropertyImage {
  id: string;
  images: Image[];
  onUpload: (key: string, images: Image[]) => void;
}

export const UploadPropertyImages = (props: IUploadPropertyImage) => {
  const onAdd = useCallback((file: File) => {
    const imageFile: Image = {
      fileName: file.name,
      filePath: URL.createObjectURL(file),
      file
    }
    const newList = [...props.images, imageFile];
    props.onUpload(props.id, newList);
  }, [props]);


  return (
    <>
      <Button variant="outlined" component="label" >
        <AddIcon /> Add Image
        <input hidden type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            onAdd(e.target.files[0]);
          }
        }} />
      </Button>
      <GridLayout gap="4px" maxWidth="120px">
        {
          props.images.map((element, index) => {
            return <UploadImage image={element} />
          })
        }
      </GridLayout>
    </>
  )
};
