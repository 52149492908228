import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { IProperty, PropertyCard } from './PropertyCard';
import { FullWidthDialog } from '../../Dialog/FullWidthDialog';
import { EditProperty, Agent } from '../EditProperty/EditProperty';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const PropertyTable = () => {

    const [properties, setProperties] = useState<IProperty[]>([]);
    const [selectedProperty, setSelectedProperty] = useState("");
    const [openDialog, setDialog] = useState(false);
    const [agents, setAgents] = useState<Agent[]>([]);

    useEffect(() => {
        void axios.get(`${globalThis.ENV_BASED_URL}get-all-agents`)
            .then((resp) => {
                const data: Agent[] = resp.data;
                setAgents(data)
            })
    }, []);

    useEffect(() => {
        axios.get(`${globalThis.ENV_BASED_URL}all-properties`)
            .then((resp) => {
                console.log(resp.data)
                const data: IProperty[] = resp.data;
                setProperties(data);
            })
    }, []);

    const onPropertySelect = useCallback((propertyId: string) => {
        setSelectedProperty(propertyId);
        setDialog(true);
    }, []);

    const closeDialog = useCallback(() => {
        setSelectedProperty("");
        setDialog(false);
    }, [])

    return (
        <div>
            <Tabs value={0} onChange={() => { }} aria-label="filter">
                <Tab label="All" id={"0"} />
                <Tab label="To reviwe" id={"1"} />
                <Tab label="Un-paid" id={"2"} />
                <Tab label="Closed" id={"3"} />
            </Tabs>
            {properties.map((property) => {
                return <PropertyCard {...property} onPropertyClick={onPropertySelect} />
            })}
            <FullWidthDialog open={openDialog} onClose={closeDialog}
                title={"Property details"}>
                <EditProperty onClose={closeDialog} propertyId={selectedProperty} agents={agents} />
            </FullWidthDialog>
        </div>
    );
}
