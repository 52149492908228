import { InputLabel, MenuItem, Select, SelectProps, FormControl } from "@material-ui/core";
import { FC } from "react";
interface IMenuItem {
    name: string;
    value: string;
}
interface DropdownType extends SelectProps {
    register: any,
    label: string,
    id: string,
    values: IMenuItem[],
};

export const DropdownHooked: FC<DropdownType> = ({ register, id, label, values, ...inputProps }) => {
    return <FormControl fullWidth size="small">
        <InputLabel id={id}>{label}</InputLabel>
        <Select
            {...register(id)}
            id={id}
            variant="outlined"
            {...inputProps}
            defaultValue={values[0]}
            label={label}>
            {values.map(value => {
                return <MenuItem value={value.value}>{value.name}</MenuItem>
            })}
        </Select>
    </FormControl>
}