import { useFormikContext } from "formik";
import { InputProps, TextField } from "@mui/material";
import React from "react";

export interface InputTextProps {
  name: string;
  label: string;
  inputProps?: InputProps;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const InputText = (props: InputTextProps) => {
  const { setFieldValue, values } = useFormikContext<typeof props.name>();
  return (<TextField size="small" label={props.label} variant="outlined" fullWidth
    value={values[props.name as any]}
    onChange={(event) => setFieldValue(props.name, event.target.value)} />);
};
