export const updateEnvUrl = () => {
  if (globalThis.ENV_BASED_URL !== undefined)
    return globalThis.ENV_BASED_URL;

  // PROD
  globalThis.ENV_BASED_URL = "https://s6cwxe7fk4.execute-api.us-east-1.amazonaws.com/";

  if (window.location.hostname.startsWith("www.dev-") || window.location.hostname.includes("localhost"))
    globalThis.ENV_BASED_URL = "https://o8a3lgcsy5.execute-api.us-east-1.amazonaws.com/";
};
