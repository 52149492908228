
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import TextField from '@mui/material/TextField';
import { useState } from "react";
import { RadioHooked } from "../../FormComponents/RadioHooked";
import { GridLayout } from "./GridLayout";

export const propertyTenure = [
    { name: "Freehold" },
    { name: "leasehold" },
    { name: "shared ownership" }
];

export const PropertyTenure = (props: { register: any }) => {

    return (
        <>
            <FormControl style={{ width: "100%" }}>
                <RadioHooked register={props.register} label={"Tenure"} id={"tenure"}
                    values={["freehold", "leasehold", "shared ownership"]} />
                {/* {
                    (tenure === propertyTenure[1].name) && (
                        <div style={{ maxWidth: "500px" }}>
                            <TextField variant="outlined" label={"Years"} size={"small"} type="number"
                                InputProps={{ inputProps: { min: 1 } }} value={year}
                                onChange={(e) => { setYear(parseInt(e.target.value)) }} />
                            <TextField variant="outlined" label={"From"} size={"small"} type="number" value={from}
                                InputProps={{ inputProps: { min: 1900, max: new Date().getFullYear() } }}
                                onChange={(e) => { setFrom(parseInt(e.target.value)) }} />
                        </div>
                    )
                } */}
            </FormControl>
        </>
    )
}