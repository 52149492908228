import { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Formik, Form } from 'formik';
import { InputText } from '../../InputComponent/InputText';
import { DropDown, IMenuItem } from '../../InputComponent/Dropdown';
import { DescriptionBox } from '../../InputComponent/DescriptionBox/DescrptionBox';
import { Typography } from '@material-ui/core';
import "./EditPropertyStyle.css";
import { CheckboxList } from '../../InputComponent/CheckboxList';

export interface Agent {
  name: string,
  agencyId: string,
}

export interface IEditProperty {
  propertyId: string;
  agents: Agent[];
  onClose: () => void
}


interface IPropertyDetail {
  propertyId: string;
  address: string;
  featured: number;
  description: string;
  listingPrice: number;
  bedRooms: number;
  bathRooms: number;
  livingRooms: number;
  propertyType: string;
  createdDate: string;
  features: string;
  propertyStatus: string;
  tenure: string;
  images: string[];
}

const roomsMenuItems: IMenuItem[] = [{ label: "1", value: 1 }, { label: "2", value: 2 },
{ label: "3", value: 3 }, { label: "4", value: 4 }, { label: "5", value: 5 }, { label: "6", value: 6 },
{ label: "7", value: 7 }, { label: "8", value: 8 }, { label: "9", value: 9 }, { label: "10", value: 10 }];


const propertyFeatures = ["driveway", "front garden", "rear garden", "storage shed", "pool",
  "sun room", "conservatory", "loft storage", "insulated loft", "gated entrance", "burglary alarm"];

export const EditProperty = ({ propertyId, onClose, agents }: IEditProperty) => {
  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetail | undefined>();

  useEffect(() => {
    if (propertyId !== "") {
      void axios.get(`${globalThis.ENV_BASED_URL}property/${propertyId}`)
        .then((response) => { setPropertyDetails(response.data); });
    }
  }, [propertyId]);


  const approveProperty = useCallback(async () => {
    await axios.post(`${globalThis.ENV_BASED_URL}approve-property`, {
      "propertyId": propertyId
    });
  }, [propertyId]);

  const editPropertyForm = useMemo(() => {
    const workingAgents = agents.map((agent) => {
      return {
        label: agent.name,
        value: agent.agencyId
      }
    });
    if (propertyDetails === undefined) {
      return <>Loading...</>
    }
    return (
      <>
        <div>
          <Typography variant='h6'>{propertyDetails.address}</Typography>
          <Typography variant='body1'>{propertyDetails.propertyType}</Typography>
          <div style={{display:"inline", maxWidth:"90%"}}>
            {
              propertyDetails.images.map((image) => {
                return (
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  <img src={image} alt="property image" style={{ height: "200px", width: "200px", padding:"4px" }} />
                )
              })
            }
          </div>
          <Formik
            initialValues={{
              description: propertyDetails!.description,
              listingPrice: propertyDetails!.listingPrice,
              bedRooms: propertyDetails!.bedRooms,
              bathRooms: propertyDetails!.bathRooms,
              livingRooms: propertyDetails!.livingRooms,
              features: propertyDetails!.features,
              propertyStatus: propertyDetails!.propertyStatus,
              tenure: propertyDetails!.tenure
            }}

            onSubmit={async (values) => {

            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <div className="marginTop">
                  <InputText label="Listing price" name="listingPrice" />
                </div>
                <div className="marginTop">
                  <DropDown label="Bedrooms" name="bedRooms" menuItems={roomsMenuItems} />
                </div>
                <div className="marginTop">
                  <DropDown label="Livingrooms" name="livingRooms" menuItems={roomsMenuItems.slice(0, 7)} />
                </div>
                <div className="marginTop">
                  <DropDown label="Bathrooms" name="bathRooms" menuItems={roomsMenuItems.slice(0, 5)} />
                </div>
                <div className="marginTop">
                  <CheckboxList label="Features" name="features" listItem={propertyFeatures} />
                </div>
                <DescriptionBox name="description" />
                <Button onClick={approveProperty}>Approve</Button>
              </Form>
            )}
          </Formik>
        </div>
        <div>
          < Formik
            initialValues={{
              agent: ""
            }}

            onSubmit={async (values) => {
              if (values.agent === "") {
                return;
              }
              const res = await axios.post(`${globalThis.ENV_BASED_URL}assign-agent-property`, {
                "propertyId": propertyId,
                "agentId": values.agent
              });
              console.log(res.data)
            }}
          >
            {({ submitForm }) => (
              <Form>
                <div className="marginTop">
                  <DropDown label="Agent" name="agent" menuItems={workingAgents} />
                </div>
                <Button onClick={submitForm}>Assign to agent</Button>
              </Form>
            )}
          </Formik >
        </div>
      </>
    )
  }, [agents, propertyDetails, approveProperty, propertyId]);

  return (
    <div>
      {editPropertyForm}
      <Button onClick={onClose}>Close</Button>
    </div>
  );
}
